import React, { FC } from 'react'
import styled from 'styled-components'
import MainPage from '../components/main-page'

const StyMessage = styled.div`
  font-size: 4rem;
  text-align: center;
`

const Error404: FC<{}> = () => (
  <MainPage>
    <StyMessage>
      Not found
    </StyMessage>
  </MainPage>
)

export default Error404
